@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  line-height: 1.5;
}

.focus-ring {
  @apply focus:ring-0 focus-visible:ring-2 focus-visible:ring-accent-100 focus-visible:ring-offset-2;
}

.focus-within-ring {
  @apply focus-within:ring-2 focus-within:ring-accent-100 focus-within:ring-offset-2;
}

.focus-border {
  @apply focus:border-0 focus-visible:border focus-visible:border-accent-100;
}

.focus-visible-within-border {
  @apply has-[:focus-visible]:border has-[:focus-visible]:border-accent-100;
}

.break-container {
  @apply mx-[calc((100vw-100%)/-2)] w-screen;
}

.alma-payment-plans-container {
  @apply rounded-lg bg-primary-30/10;
}

.inputDate::-webkit-datetime-edit-ampm-field:focus,
.inputDate::-webkit-datetime-edit-day-field:focus,
.inputDate::-webkit-datetime-edit-hour-field:focus,
.inputDate::-webkit-datetime-edit-millisecond-field:focus,
.inputDate::-webkit-datetime-edit-minute-field:focus,
.inputDate::-webkit-datetime-edit-month-field:focus,
.inputDate::-webkit-datetime-edit-second-field:focus,
.inputDate::-webkit-datetime-edit-week-field:focus,
.inputDate::-webkit-datetime-edit-year-field:focus {
  @apply bg-primary-30 text-white;
}

.inputDate::-webkit-datetime-edit-fields-wrapper {
  @apply ml-1;
}

/* CSS styling for the cms components throughout the site. Needs to be reviewed if we can fix this in another way. */
h3 {
  font-size: 1.5rem;
  font-weight: 700;
}

h4 {
  font-size: 1.25rem;
  font-weight: 600;
}

p {
  margin-bottom: 1rem;
}

#onetrust-consent-sdk {
  pointer-events: auto;
}

.scrollbar-hide {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
